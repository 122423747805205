import React from 'react';
import { Spoiler, Table, List, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import team1 from '../../assets/images/team-1-1.jpg';
import adebayo from '../../assets/images/departments/hae/adebayo.jpg';
import adegunle from '../../assets/images/departments/hae/adegunle.jpg';
import adelabu from '../../assets/images/departments/hae/adelabu.jpg';
import adeleye from '../../assets/images/departments/hae/adeleye.jpg';
import ademola from '../../assets/images/departments/hae/ademola.jpg';
import adeyemo from '../../assets/images/departments/hae/adeyemo.png';
import adiat from '../../assets/images/departments/hae/adiat.jpg';
import ajaegbe from '../../assets/images/departments/hae/ajaegbe.png';
import ajao from '../../assets/images/departments/hae/ajao.png';
import akanmu from '../../assets/images/departments/hae/akanmu.png';
import akanni from '../../assets/images/departments/hae/akanni.png';
import akinwale from '../../assets/images/departments/hae/akinwale.png';
import aneke from '../../assets/images/departments/hae/aneke.png';
import anna from '../../assets/images/departments/hae/anna.png';
import anthony from '../../assets/images/departments/hae/anthony.png';
import atinuke from '../../assets/images/departments/hae/atinuke.jpg';
import bamidele from '../../assets/images/departments/hae/bamidele.jpg';
import bello from '../../assets/images/departments/hae/bello.png';
import bosede from '../../assets/images/departments/hae/bosede.jpg';
import chijoke from '../../assets/images/departments/hae/chijoke.png';
import chizoba from '../../assets/images/departments/hae/chizoba.png';
import edewor from '../../assets/images/departments/hae/edewor.png';
import ekaete from '../../assets/images/departments/hae/ekaete.png';
import fayoke from '../../assets/images/departments/hae/fayoke.jpg';
import fredrick from '../../assets/images/departments/hae/fredrick.jpg';
import idowu from '../../assets/images/departments/hae/idowu.png';
import ikhuoriah from '../../assets/images/departments/hae/ikhuoriah.png';
import irobi from '../../assets/images/departments/hae/irobi.jpg';
import lawal from '../../assets/images/departments/hae/lawal.jpg';
import musa from '../../assets/images/departments/hae/musa.png';
import odeh from '../../assets/images/departments/hae/odeh.png';
import odofin from '../../assets/images/departments/hae/odofin.png';
import ogbeide from '../../assets/images/departments/hae/ogbeide.png';
import ogbenna from '../../assets/images/departments/hae/ogbenna.png';
import oguh from '../../assets/images/departments/hae/oguh.png';
import ogundipe from '../../assets/images/departments/hae/ogundipe.png';
import ogunnaike from '../../assets/images/departments/hae/ogunnaike.jpg';
import okanlawon from '../../assets/images/departments/hae/okanlawon.png';
import oladapo from '../../assets/images/departments/hae/oladapo.jpg';
import olatinwo from '../../assets/images/departments/hae/olatinwo.jpg';
import olayinka from '../../assets/images/departments/hae/olayinka.jpg';
import olowoselu from '../../assets/images/departments/hae/olowoselu.png';
import olusegun from '../../assets/images/departments/hae/olusegun.png';
import omodele from '../../assets/images/departments/hae/omodele.png';
import opadeyi from '../../assets/images/departments/hae/opadeyi.png';
import onwueyi from '../../assets/images/departments/hae/onwueyi.png';
import oremosu from '../../assets/images/departments/hae/oremosu.png';
import osunkalu from '../../assets/images/departments/hae/osunkalu.png';
import owolabi from '../../assets/images/departments/hae/owolabi.png';
import oyelaran from '../../assets/images/departments/hae/oyelaran.png';
import patience from '../../assets/images/departments/hae/patience.png';
import raman from '../../assets/images/departments/hae/raman.jpg';
import sanusi from '../../assets/images/departments/hae/sanusi.jpg';
import samuel from '../../assets/images/departments/hae/samuel.png';
import shobayo from '../../assets/images/departments/hae/shobayo.png';
import taylor from '../../assets/images/departments/hae/taylor.png';
import temitope from '../../assets/images/departments/hae/temitope.png';
import ugwuoke from '../../assets/images/departments/hae/ugwuoke.png';
import zergbem from '../../assets/images/departments/hae/zergbem.jpg';

const Haematology = () => {
  const staff = [
    {
      name: 'DR. Ogbenna Ann Abiola ',
      designation: 'Consultant / Head Of Department',
      image: ogbenna,
      specialty: 'Haematology & Blood Transfusion',
      qualification: 'Mbbs, Fmcpath (2007)',      
    },
    {
      name: 'Prof. Akanmu Alani Sulaiman',
      designation: 'Consultant Haematologist & Blood Transfusion',
      image: akanmu,
      specialty: 'HIV Medicine',
      qualification: 'MBBS, MWACP (Lab Med) FMC Path ',      
    },
    {
      name: 'Dr. Osunkalu Vincent Oluseye',
      designation: 'Honorary Consultant',
      image: osunkalu,
      specialty: 'Haematology & Blood Transfusion',
      qualification: 'MBCHB, FMCPath, MSc, Ph.D.',      
    },
    {
      name: 'Dr. Adeyemo Titilope Adenike',
      designation: 'Consultant',
      image: adeyemo,
      specialty: 'Haematology & Blood Transfusion',
      qualification: 'MBBS, MSc, FMCPath (2008)',      
    },
    {
      name: 'Dr. Olowoselu Festus Olusola',
      designation: 'Consultant',
      image: olowoselu,
      specialty: 'Haematology & Blood Transfusion',
      qualification: 'MBBS, FMCPath (2013)',      
    },
    {
      name: 'Dr. Edewor-Okiyo Oghale Deborah',
      designation: 'Full Time Consultant',
      image: edewor,
      specialty: 'Haematology & Blood Transfusion',
      qualification: 'MBBS',      
    },
    {
      name: 'Dr. Opadeyi Oluwaseyi Micheal',
      designation: 'Senior Registrar I',
      image: opadeyi,
      specialty: 'Haematology & Blood Transfusion',
      qualification: '',      
    },
    {
      name: 'Dr. Olatinwo Adejoke Temidayo',
      designation: 'Senior Registrar I',
      image: olatinwo,
      specialty: 'Haematology & Blood Transfusion',
      qualification: '',      
    },
    {
      name: 'Dr. Adiat Adeniyi Lateef',
      designation: 'Senior Registrar I',
      image: adiat,
      specialty: 'Haematology & Blood Transfusion',
      qualification: '',      
    },
    {
      name: 'Dr. Ogunnaike-Quaye Temitope Benard',
      designation: 'Registrar',
      image: ogbenna,
      specialty: 'Haematology & Blood Transfusion',
      qualification: 'SSCE, M.B.CH.B',      
    },
    {
      name: 'Dr. Okanlawon Olayiwola Jamiu',
      designation: 'Registrar',
      image: okanlawon,
      specialty: 'Haematology & Blood Transfusion',
      qualification: '',      
    },
    {
      name: 'Dr. Adegunle Benjamin Adewale',
      designation: 'Registrar',
      image: adegunle,
      specialty: 'Haematology & Blood Transfusion',
      qualification: 'MBBS',      
    },
    {
      name: 'Dr. Idowu Olufunmilola Olusola',
      designation: 'Senior Medical Officer',
      image: idowu,
      specialty: 'HIV Medicine (Art Clinic)',
      qualification: 'MBCHB OAU (2005)',      
    },
    {
      name: 'Dr. Ikhuoriah Uhomoaze Paul',
      designation: 'Senior Medical Officer',
      image: ikhuoriah,
      specialty: 'HIV MEDICINE, ART MEDICINE',
      qualification: 'MBBS',      
    },
    {
      name: 'Dr. Odofin Mayowa',
      designation: 'Senior Medical Officer',
      image: odofin,
      specialty: 'Hiv Medicine (Art Clinic)',
      qualification: 'MBBS, MBS',      
    },
    {
      name: 'Mrs. Oremosu Ayoola Islamiyat',
      designation: 'Deputy Director, Laboratory Services',
      image: oremosu,
      specialty: 'Haematology & Blood Transfusion',
      qualification: 'AMLSCN, FMLSCN, MSc',      
    },
    {
      name: 'Mrs. Chijioke Lydia Oluwakemi',
      designation: 'Deputy Director (Laboratory Services)',
      image: chijoke,
      specialty: 'Medical Laboratory Scientist',
      qualification: 'FMLSCN (HISTOPATHOLOGY) AMLSCN (HAEM & BIOLOGY)',      
    },
    {
      name: 'Mrs. Ojeikere Esther',
      designation: 'Deputy Director (Laboratory Services) ',
      image: team1,
      specialty: 'Medical Lab Scientist',
      qualification: 'BMLS, AMLSCN, MPH',      
    },
    {
      name: 'Mrs. Akanni Adenike Anastasia',
      designation: 'Assistant Director (Laboratory Service)',
      image: akanni,
      specialty: 'Medical Lab Scientist',
      qualification: 'AMLS, FMLS',      
    },
    {
      name: 'Mrs. Enotiemoria Anna Onokuekhia',
      designation: 'Assistant Director (Laboratory Service)',
      image: anna,
      specialty: 'Medical Lab Scientist',
      qualification: 'BMLS',      
    },
    {
      name: 'Mr. Ugwuoke Anthony Emeka',
      designation: 'Assistant Director (Laboratory Services)',
      image: ugwuoke,
      specialty: 'Medical Lab Scientist',
      qualification: 'MPH, BMLS, AMLSCN',      
    },
    {
      name: 'Mrs. Shobayo Olayinka Olayiwole',
      designation: 'Chief Medical Lab Scientist',
      image: shobayo,
      specialty: 'Medical Lab Scientist',
      qualification: 'BMLS',      
    },
    {
      name: 'Mrs. Adebayo Rosemary Nwamaka',
      designation: 'Chief Medical Lab Scientist',
      image: adebayo,
      specialty: 'Medical Lab Scientist',
      qualification: 'Master Med Microbiology, FMLSCN',      
    },
    {
      name: 'Mrs. Oyelaran Dorcas Olubunmi ',
      designation: 'Principal Medical Laboratory Scientist',
      image: oyelaran,
      specialty: 'Medical Lab Scientist',
      qualification: 'BSC, FMLSCN, AMLSCN',      
    },
    {
      name: 'Mr. Anthony Nnaemeka Nzewi',
      designation: 'Chief Medical Lab Scientist',
      image: anthony,
      specialty: 'Medical Lab Scientist',
      qualification: 'BMLS, MSC',      
    },
    {
      name: 'Mrs. Aneke Julian Ngozi',
      designation: 'Medical Lab Scientist',
      image: aneke,
      specialty: 'Medical Lab Scientist',
      qualification: 'Associate Of Med Laboratory Science',      
    },
    {
      name: 'Mr. Jaiyesimi Oludotun Olusegun',
      designation: 'Chief Medical Lab Scientist',
      image: olusegun,
      specialty: 'ART HTS Clinical Laboratory',
      qualification: 'AMLSN, FMLSN, B. TECH',      
    },
    {
      name: 'Mr. Bello Ohiwere Sunday',
      designation: 'Principal Medical Lab Scientist',
      image: bello,
      specialty: 'Component / TTIS',
      qualification: 'MSC, BMLS',      
    },
    {
      name: 'Mrs. Aiyejusunle Jolaade Fayoke',
      designation: 'Chief Nursing Officer ',
      image: fayoke,
      specialty: 'Voluntary Donor Requirement',
      qualification: 'Registered Nurse, Registered Midwife',      
    },
    {
      name: 'Mrs. Ade-Alabi Grace Omodele',
      designation: 'Scientific Officer II',
      image: omodele,
      specialty: 'Laboratory',
      qualification: 'BSC Microbiology',      
    },
    {
      name: 'Mr. Adekunle Ismaila Ajao',
      designation: 'Senior Laboratory Technology',
      image: ajao,
      specialty: 'Laboratory Administration',
      qualification:
        'OND, Final Diploma UNAAB, Final Diploma Institute Of Science & Tech',      
    },
    {
      name: 'Mr. Taylor Babayinka Johnson',
      designation: 'Medical Laboratory Technician',
      image: taylor,
      specialty: 'Laboratory',
      qualification: 'Medical Laboratory Technician',      
    },
    {
      name: 'Mrs. Ogundipe Rebecca F.',
      designation: 'Principal Medical Lab Technician',
      image: ogundipe,
      specialty: 'Laboratory',
      qualification: 'Medical Laboratory Technician',      
    },
    {
      name: 'Mrs. Ekaete Obi-Omenukwa',
      designation: 'Chief Medical Lab Technician',
      image: ekaete,
      specialty: 'Laboratory',
      qualification: 'Medical Laboratory Technician',      
    },
    {
      name: 'Mrs. Ajaegbe Olaide Raimot',
      designation: 'Medical Laboratory Technician',
      image: ajaegbe,
      specialty: 'Laboratory',
      qualification: 'Medical Laboratory Technician',      
    },
    {
      name: 'Mrs. Onwueyi Amaka Patience Chibuinine',
      designation: 'Chief Confidential Secretary ',
      image: onwueyi,
      specialty: 'Administrative Duties',
      qualification:
        'B.SC Publication Administration, NABTEB Cert, Secretary Administration Teachers Grade II, WAEC.',      
    },
    {
      name: 'Oguh Esther Chinenye',
      designation: 'Senior Confidential Secretary',
      image: oguh,
      specialty: 'Art Clinic Counselling',
      qualification: 'HND',      
    },
    {
      name: 'Mr. Zergbem Ahemba Benjamin',
      designation: 'Senior Executive Officer',
      image: zergbem,
      specialty: '',
      qualification: '',      
    },
    {
      name: 'Mrs. Asaba Christiana Bassey',
      designation: 'Executive Officer',
      image: team1,
      specialty: '',
      qualification: 'OND',      
    },
    {
      name: 'Mrs. Oladapo Surajat Omolara',
      designation: 'Clerical Officer',
      image: oladapo,
      specialty: '',
      qualification: 'SSCE, BSC Microbiology, MSC Medical Parasitology',      
    },
    {
      name: 'Mrs. Lawal Bakare Abiodun',
      designation: 'Data Processing Assistant I',
      image: lawal,
      specialty: '',
      qualification:
        'Diploma in Computer Operations, Advance Cert in Desktop Publishing, SSCE, B.SC Accounting, Proficiency Cert. In Management',      
    },
    {
      name: 'Mr. Owolabi Tope Emmanuel',
      designation: 'Senior Scientific Officer',
      image: owolabi,
      specialty: 'Laboratory Services',
      qualification: 'B. TECH (HONS)',      
    },
    {
      name: 'Mrs. Irobi Magdaene Ige',
      designation: 'Head Health Attendance',
      image: irobi,
      specialty: 'Peer Counselor and Nurse Assistant',
      qualification: 'Vocational Certificate, O” Level',      
    },
    {
      name: 'Mrs. Odeh Ashodi Ruth',
      designation: 'Medical Social Worker',
      image: odeh,
      specialty: 'Counselling',
      qualification: 'School Certificate O Level',      
    },
    {
      name: 'Mrs. Olayemi Elizabeth Adeola',
      designation: 'Higher Laboratory Suprintendent',
      image: team1,
      specialty: 'Laboratory Services',
      qualification: 'HND',      
    },
    {
      name: 'Mr. Akinrodoye Temitope',
      designation: 'Senior Executive Officer',
      image: temitope,
      specialty: 'Laboratory Data Capturing',
      qualification: 'HND',      
    },
    {
      name: 'Mr. Ogundare Abidemi Olayinka',
      designation: 'Senior Programme Officer',
      image: olayinka,
      specialty: 'APIN Clinic / Data Officer/ M&E',
      qualification: 'B.SC',      
    },
    {
      name: 'Mrs. Adeyekun Abisola Bosede',
      designation: 'Senior Clerical Officer',
      image: bosede,
      specialty: 'Clerical Officer',
      qualification: 'WAEC',      
    },
    {
      name: 'Mr. Musa Abdullahi Aiyegbeni',
      designation: 'Senior Laboratory Superintendent',
      image: musa,
      specialty: 'Art HTS Clinical Laboratory',
      qualification:
        'Masters in Public Health (MPH), PGD, HND, (Science Laboratory Technology)',      
    },
    {
      name: 'Mr. Bello Ayopade Ademola',
      designation: 'Senior Assistant Data Processor Asst 1',
      image: ademola,
      specialty: '',
      qualification: 'G.C.E O-LEVEL, B.SC Computer Science',      
    },
    {
      name: 'Mr. Sanusi Afeez Adewale',
      designation: 'Senior Executive Officer',
      image: sanusi,
      specialty: 'Art Clinic Data/M&E Officer',
      qualification: 'National Diploma',
    },
    {
      name: 'Ms. Atinuke Alimatsadiat T.',
      designation: 'Clerical Officer II',
      image: atinuke,
      specialty: 'Clerical Officer',
      qualification: 'W.A.E.C & G.C.E',      
    },
    {
      name: 'Mr. Ogedengbe Abayomi Samuel',
      designation: 'Senior Clerical Officer',
      image: samuel,
      specialty: 'Clerical Officer',
      qualification: 'S.S.C.E, O’ LEVEL',      
    },
    {
      name: 'Mr. Ogbeide Godwin Friday',
      designation: 'Senior Executive Officer',
      image: ogbeide,
      specialty: 'Clerical Officer',
      qualification: 'Clerical Officer II',      
    },
    {
      name: 'Ms. Ekezie Ezinne Sonia',
      designation: 'Senior Clerical Officer',
      image: team1,
      specialty: 'Clerical Work',
      qualification: 'S.S.C.E',      
    },
    {
      name: 'Mr. Bamidele Oludare Olubayo',
      designation: 'Senior Executive Officer',
      image: bamidele,
      specialty: 'Voluntary Donor Recruitment Officer',
      qualification: 'O’ Level',      
    },
    {
      name: 'Mr. Adeleye Lukman Adeshola',
      designation: 'Clerical Officer',
      image: adeleye,
      specialty: 'Clerical Work',
      qualification: 'S.S.C.E',      
    },
    {
      name: 'Mr. Adelabu Hameed Adewale',
      designation: 'Senior Data Processing Officer',
      image: adelabu,
      specialty: 'Clinical Data Management',
      qualification: 'B.SC Computer Science',      
    },
    {
      name: 'Mrs. Nyoyoko Patience',
      designation: 'Head Porter',
      image: patience,
      specialty: 'Clerical Officer',
      qualification: 'S.S.C.E',      
    },
    {
      name: 'Mr. Fredrick Chinenye Wilfred',
      designation: 'Chief Clerical Officer',
      image: fredrick,
      specialty: 'Admin/ Clerical Duties',
      qualification: 'S.S.C.E, G.C.E, OND Business Administration',      
    },
    {
      name: 'Mrs. Akinwale Oluwatoyin Romoke',
      designation: 'Chief Medical Laboratory Scientist',
      image: akinwale,
      specialty: 'Laboratory Scientist',
      qualification: 'B.M.LS, F.M.LS ',      
    },
  ];

  return (
    <Layout pageTitle='Department of Haematology And Blood Transfusion | LUTH'>
      <NavOne />
      <PageHeader title='Department of Haematology And Blood Transfusion' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Haematology And Blood Transfusion
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The Department of Haematology and Blood Transfusion is
                        one of four Pathology Departments in the Lagos
                        University Teaching Hospital. The department offers both
                        routine and specialized haematology laboratory services
                        including blood banking services to the extensive
                        clientele of the Lagos University Teaching Hospital. It
                        also provides clinical haematological services and
                        cutting edge medical research. The department is
                        involved in undergraduate as well as postgraduate
                        training required specialists with high level skills and
                        training.
                        <br />
                        <br /> The activities of the department also includes
                        specialist clinical services with weekly consultation
                        clinics, daily daycare clinical services and ward rounds
                        for patients with haematological pathologies. The
                        department is at the forefront of awareness and
                        management of haemophilia patients with extensive
                        experience in the care of such patients. Haematology and
                        blood transfusion department also runs the APIN clinic
                        for diagnosis management and follow up of HIV infected
                        patients. The departments also house the molecular Lab
                        in the hospital. These various activities have made the
                        department central to patient care and training of
                        residents from even departments outside pathology.{' '}
                        <br />
                        <br />
                        The Department has a team of 6 Consultant
                        Haematologists, 6 resident doctors, 20 Laboratory
                        Scientists, 1 Nurse, 8 Technicians, 35 Clerks, 1
                        Confidential Secretary, 1 Data Processor and 7
                        housekeeping staff. This team provides the needed
                        manpower to meet up with the lofty aims of the
                        department enhancing patient care in line with the
                        mandate of the Lagos University Teaching.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The department is involved in undergraduate as well as
                        postgraduate training required specialists with high
                        level skills and training.
                        <br />
                        <br /> The activities of the department also includes
                        specialist clinical services with weekly consultation
                        clinics, daily daycare clinical services and ward rounds
                        for patients with haematological pathologies. The
                        department is at the forefront of awareness and
                        management of haemophilia patients with extensive
                        experience in the care of such patients. Haematology and
                        blood transfusion department also runs the APIN clinic
                        for diagnosis management and follow up of HIV infected
                        patients. The departments also house the molecular Lab
                        in the hospital. These various activities have made the
                        department central to patient care and training of
                        residents from even departments outside pathology.{' '}                        
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      {staff.map((member, idx) => (
                        <div key={idx} className='blog-details__author'>
                          <div className='blog-details__author-image'>
                            <img src={member.image} alt='profile person' />
                          </div>
                          <div className='blog-details__author-content'>
                            <h3>{member.name}</h3>
                            <p>
                              <span className='detail-head'>Designation:</span>{' '}
                              {member.designation} <br />
                              {member.specialty && (
                                <>
                                  <span className='detail-head'>
                                    Specialty:
                                  </span>{' '}
                                  {member.specialty}
                                  <br />
                                </>
                              )}
                              {member.qualification && (
                                <>
                                  <span className='detail-head'>
                                    Qualification:
                                  </span>{' '}
                                  {member.qualification}
                                  <br />
                                </>
                              )}
                              {member.contact && (
                                <>
                                  <span className='detail-head'>Contact:</span>{' '}
                                  {member.contact}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      ))}
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:heambt@luth.gov.ng'>                        
                        heambt@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Haematology;
